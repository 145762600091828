<template>
  <div>
    <div class="header">
      <div class="headerleft" @click="$router.go(-1)">
        <van-icon name="arrow-left" />
      </div>
      <div class="headercenter" @click="$router.push('/')">
        <img src="../assets/logo.png" />
      </div>
      <div class="headerright"></div>
    </div>

    <div class="loading" v-if="showloading">
      <van-loading type="spinner" color="#206C47" size="24px" vertical>
        加载中...
      </van-loading>
    </div>

    <div class="container" v-show="!showloading && goodsList.id">
      <div class="banner">
        <van-swipe class="my-swipe" indicator-color="#206C47">
          <van-swipe-item
            v-for="(item, index) in goodsList.img_arr"
            :key="index"
          >
            <img :src="item" alt="" @click="getImg(index)" />
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="goods">
        <div class="goodsTitle">
          <div class="goodsName">{{ goodsList.name }}</div>
          <div class="goodsPrice">￥{{ goodsList.price }}</div>
        </div>
        <div class="goodsDetails">
          <!-- 吸顶 -->
          <div class="tabs" id="boxFixed" :class="isFixed ? 'tabsFixed' : ''">
            <div
              class="tabItem"
              :class="tabItemActive == 1 ? 'tabItemActive' : ''"
              @click="tabItemActive = 1"
            >
              商品简介
            </div>
            <div
              class="tabItem"
              :class="tabItemActive == 2 ? 'tabItemActive' : ''"
              @click="tabItemActive = 2"
            >
              商品说明
            </div>
            <div
              class="tabItem"
              :class="tabItemActive == 3 ? 'tabItemActive' : ''"
              @click="tabItemActive = 3"
              v-if="goodsList.report_img"
            >
              检测报告
            </div>
          </div>

          <div class="details">
            <!-- <div class="emptybox1"></div> -->
            <div v-html="goodsList.content" v-if="tabItemActive == 1"></div>
            <div v-html="goodsList.content_gw" v-if="tabItemActive == 2"></div>
            <div v-if="tabItemActive == 3">
              <img
                class="report"
                :src="item"
                v-for="(item, index) in goodsList.report_img"
                :key="index"
              />
            </div>
            <!-- <div class="emptybox2"></div> -->
          </div>
        </div>
      </div>

      <div class="toBuy" @click="showQR = true">去购买</div>
      <div class="QR" v-if="showQR">
        <div class="QRimgbox">
          <img class="QRimg" :src="goodsQR" />
          <van-icon class="icon" name="cross" @click="showQR = false" />
        </div>
        <div class="QRtext">长按小程序码，即可下单</div>
      </div>
    </div>

    <div class="contain" v-if="!showloading && !goodsList.id && notfound">
      <div class="banner">
        <img class="bannerImg" src="../assets/404bgc.jpg" />
      </div>
      <div class="page">
        <img src="../assets/404.png" />
        <div class="en">EXCUSE ME</div>
        <div class="ch">很抱歉，您访问的页面不存在！</div>
        <div class="back" @click="$router.push('/')">返回首页</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Loading, List, Swipe, SwipeItem, ImagePreview } from "vant";
import qs from "qs";

export default {
  components: {
    [Icon.name]: Icon,
    [Loading.name]: Loading,
    [List.name]: List,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      showloading: true,
      goodsList: [], //商品详情
      tabItemActive: 1, //导航栏高亮
      showQR: false, //是否显示小程序码
      goodsQR: "", //商品小程序二维码
      isFixed: false, //是否吸顶
      offsetTop: 0,
      notfound: false, //404
    };
  },
  mounted() {
    this.getData(this.$route.query.id);
    this.getQR(this.$route.query.id);

    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 点击图片放大
    getImg(index) {
      ImagePreview({
        images: this.goodsList.img_arr, // 预览图片的那个数组
        showIndex: true,
        loop: false,
        startPosition: index, // 指明预览第几张图
      });
    },

    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log("scrollTop", scrollTop);
      //如果被卷曲的高度大于吸顶元素到顶端位置 的距离
      this.isFixed = scrollTop > this.offsetTop ? true : false;
    },

    getData(id) {
      //   获取商品详情
      let data1 = qs.stringify({
        id: id,
      });
      this.$axios
        .post(
          "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=product&m=pro_index1",
          data1
        )
        .then((res) => {
          // console.log(res.pro);
          this.showloading = false;
          if (res.status == 1) {
            this.goodsList = res.pro;
            document.title = this.goodsList.name + "-商品展示-无邪生物科技";
            setTimeout(() => {
              // 计算吸顶元素到页面顶部的距离
              this.$nextTick(() => {
                let box = document.getElementById("boxFixed");
                this.offsetTop = box.offsetTop;
                // console.log("offsetTop", this.offsetTop);
              });
            }, 100);
          } else {
            this.notfound = true;
          }
        });
    },

    getQR(id) {
      //   获取商品小程序二维码
      let data1 = qs.stringify({
        id: id,
      });
      this.$axios
        .post(
          "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=getcode&m=get_qrcode",
          data1
        )
        .then((res) => {
          // console.log(res);
          if (res.status == 1) {
            this.goodsQR = res.url;
          }
        });
    },

    openShare() {
      let config = {
        link: window.location.href, // 网址，默认使用window.location.href
        // title: this.detail.course_data.name, // 标题，默认读取document.title
        imgUrl: this.goodsList.img_arr[0], // 图片, 默认取网页中第一个img标签
        types: ["wx", "wxline", "qq", "qzone", "sina"], // 启用的社交分享,默认为全部启用
        fnDoShare(type) {
          // console.log(type);
          // 执行分享的回调，type为'wx', 'wxline', 'qq', 'qzone', 'sina'
        },
      };
      let ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        Mshare.to("qq", {
          link: window.location.href,
          // title: this.detail.course_data.name,
          imgUrl: this.goodsList.img_arr[0],
        });
      } else {
        Mshare.popup(config);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 60%;
}

.header {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.75rem;
  background-color: #fff;
  padding: 0 1rem;
  border-bottom: 1px solid #ececec;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  .headerleft {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
  }
  .headercenter {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 1.6875rem;
      width: 2rem;
    }
  }
  .headerright {
    width: 1.5rem;
  }
}

.container {
  margin-top: 2.75rem;
  line-height: 1;
  position: relative;
}

.banner {
  /deep/ .van-swipe-item {
    color: #fff;
    line-height: 110px;
    text-align: center;
    background-color: #fff;
    img {
      width: 100%;
    }
  }
  /deep/ .van-swipe__indicator {
    background-color: #8fb5a3;
    width: 0.625rem;
    height: 0.625rem;
  }
}

.goods {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3.375rem;
  .goodsTitle {
    color: #206c47;
    margin: 2rem 0 3.0625rem 0;
    .goodsName {
      font-weight: 700;
      font-size: 1.125rem;
    }
    .goodsPrice {
      margin-top: 1.5rem;
      font-size: 1.1875rem;
      text-align: center;
    }
  }
  .goodsDetails {
    width: 100vw;
    .tabs {
      background-color: #fff;
      display: flex;
      align-items: center;
      padding: 0 1rem;
      border-bottom: 1px solid #ededed;
      height: 2.875rem;
      .tabItem {
        color: #999999;
        font-size: 1rem;
        margin-right: 1.875rem;
        padding: 0.5rem 0;
        border-bottom: 2px solid #fff;
      }
      .tabItemActive {
        color: #206c47;
        border-bottom: 2px solid #206c47;
      }
    }
    .tabsFixed {
      position: fixed;
      top: 2.75rem;
      left: 0;
      width: 100%;
    }
    .details {
      padding: 0 1rem;
      div {
        font-size: 0.875rem;
        color: #333;
        margin: 1.875rem 0 3.0625rem 0;
        line-height: 1.5;
      }
      .report {
        width: 100%;
      }
      .emptybox1 {
        background-color: #fff;
        height: 1.875rem;
      }
      .emptybox2 {
        background-color: #fff;
        height: 3.0625rem;
      }
    }
  }
}

.toBuy {
  width: 100%;
  background-color: #206c47;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 1.125rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
}

.QR {
  width: 200px;
  height: 240px;
  position: fixed;
  bottom: 3.5rem;
  left: 50%;
  transform: translate(-50%, 0);
  border: 2px solid #206c47;
  background-color: #fff;
  .QRimgbox {
    height: 196px;
    width: 196px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .QRimg {
      height: 160px;
      width: 160px;
    }
    .icon {
      position: absolute;
      top: 0.375rem;
      right: 0.375rem;
      font-size: 1.25rem;
    }
  }
  .QRtext {
    background-color: #206c47;
    color: #fff;
    font-size: 16px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.contain {
  width: 100%;
  // margin-top: 2.6875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .banner {
    width: 100%;
    font-size: 0;
    .bannerImg {
      width: 100%;
    }
  }
  .page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #206c47;
    line-height: 1;
    img {
      height: 4.28125rem;
    }
    .en {
      font-size: 0.6875rem;
      margin: 2.0625rem 0 0.75rem 0;
    }
    .ch {
      font-size: 0.75rem;
    }
    .back {
      font-size: 0.75rem;
      border: 1px solid #206c47;
      padding: 0.625rem 1.5rem;
      border-radius: 1.875rem;
      margin-top: 1.375rem;
    }
  }
}
</style>